import React from 'react'

import banner from '../../assets/homebannerdesign.jpeg'

import './Home.css'

export default function Home() {
    return (
        <div className="Home">
            <img className="Home-banner" src={banner} alt="ordinateur"/>
            <div className="Home-block">
                <h1 className="Home-title"><span>Dev&Info</span>, entreprise spécialisée dans le dépannage informatique à domicile, a pour objectif d'apporter une prestation en milieu rural.</h1>
                <div className="btn-container">
                    <ul className='btn-services'>
                        <li><a className="btn-link" href="/services">Dépannage informatique</a></li>
                        <li><a className="btn-link" href="/services">Réparation de téléphones ou tablettes</a></li>
                        <li><a className="btn-link" href="/services">Reconditionnement PC fixes ou portables</a></li>
                        <li><a className="btn-link" href="/services">Formation numérique</a></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
