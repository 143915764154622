import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import Confidential from './screens/Confidential/Confidential.jsx';
import Contact from './screens/Contact/Contact.jsx';
import Footer from './components/Footer/Footer.jsx';
import Home from './screens/Home/Home.jsx';
import Legal from './screens/Legal/Legal.jsx';
import NavBar from './components/NavBar/NavBar.jsx';
import Prices from './screens/Prices/Prices.jsx';
import Services from './screens/Services/Services.jsx';

import './App.css';

export default function App() {
  return (
    <div className="App">
      <Router>
        <NavBar/>
        <Routes>
          <Route exact path='/' element={<Home/>}/>
        </Routes>
        <Routes>
          <Route exact path='/services' element={<Services/>}/>
        </Routes>
        <Routes>
          <Route exact path='/prices' element={<Prices/>}/>
        </Routes>
        <Routes>
          <Route exact path='/contact' element={<Contact/>}/>
        </Routes>
        <Routes>
          <Route exact path='/legal' element={<Legal/>}/>
        </Routes>
        <Routes>
          <Route exact path='/confidential' element={<Confidential/>}/>
        </Routes>
        <Footer/>
      </Router>
    </div>
  );
}

