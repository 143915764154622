import React from 'react'

import './Footer.css'

export default function Footer() {
    const title = "Dev&Info"
  return (
      <footer class="footer">
        <div class="footer-links">
            <a href="">{title} Tous Droits Réservés © 2022</a>
            <a href="/confidential">Politique de confidentialité</a>
            <a href="/legal">Mentions légales</a>
        </div>
      </footer>
  )
}
