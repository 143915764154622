import React from 'react'

import contact from '../../assets/homebannerdesign.jpeg'
import './Contact.css'

function Contact() {
  const title = "Dev&Info"
  return (
    <div>
      <section className="section-contact">
        <h1 className="section-title-contact">Contact</h1>
        <img className="picture-banner" src={contact} alt="ordinateur" />
        <article  className ="contact">
          <div className="bloc-contact">
            <p className="adress-contact">
              <a href = "mailto:devandinfo@gmail.com" rel='noopener noreferrer' target='_blank' className="adress-holder">
              dlc.devandinfo@gmail.com
              </a>
            </p>
            <div className="bloc-contact-phone">
              <p className="icons">
                <a href = "tel:0682844760" rel='noopener noreferrer' target='_blank' className="adress-holder">
                  06.82.84.47.60
                </a> 
              </p>
            </div>
          </div>
        </article>
    </section>
    </div>
  )
}

export default Contact