import React from 'react'

import './Legal.css'

export default function Legal() {
  const title = "Dev&Info"
  return (
    <div className="section-ml">
      <h1 className="section-big-title-legal">Mentions Légales</h1>
      <div className="section-identite">
        <div className='identite-block'>
          <article  className="identite">
              <h2 className="subtitle-legal">Identité</h2>
              <p className="description-legal">{title}</p>
              <p className="description-legal">Dorothée Laget-Chappert</p>
              <p className="description-legal">279 chemin du Suchaut</p>
              <p className="description-legal">24750 Cornille</p>
              <p className="description-legal">06.82.84.47.60</p>
              <p className="description-legal">dlc.devandinfo@gmail.com</p>
              <p className="description-legal">https://www.devandinfo.fr</p>
              <p className="description-legal">Siret : 490 007 150 00025</p>
          </article>
        </div>
        <div div className='identite-block'>
          <article  className="identite">
              <h2 className="subtitle-legal">Hébergement</h2>
              <p className="description-legal">PlanetHoster</p>
              <p className="description-legal">4416 Louis B. Mayer, Laval Québec</p>
              <p className="description-legal">Canada – H7P 0G1</p>
              <p className="description-legal">01.76.60.41.43</p>
              <p className="description-legal">https://www.planethoster.net</p>
          </article>
          <article  className="identite credit">
              <h2 className="subtitle-legal">Crédits</h2>
              <p className="description-legal">Site réalisé par Dorothée Laget-Chappert</p>
              <p className="description-legal">Contenu éditorial : Dorothée Laget-Chappert</p>
          </article>
        </div>
      </div>
    </div>
  )
}