import React from 'react'

import './Services.css'

export default function Services() {
  return (
        <div className="section">
            <h1 className="section-title">Les prestations</h1>
            <div className="section-bloc">
                <div className="section-bloc-text">
                    <a className="link-services" href="/prices">
                        <div className='section-text-picture'>
                            <h2 className="section-bloc-h2">Dépannage informatique</h2>
                            <p className="section-bloc-text-text">
                                Votre ordinateur est lent, il plante totalement ou encore vous avez une grosse alerte qui s'affiche. Et c'est à ce moment-là que la panique s'installe.<br/><br/> 
                                N'y cédez surtout pas et contactez-nous. Nous vous prodiguerons les premiers conseils et chercherons ensemble un rendez-vous si cela s'avère nécessaire.<br/><br/>
                                Plus besoin de vous déplacer, nous intervenons directement à votre domicile.<br/><br/>
                                <span className='click-prices'>👆 Découvrir les tarifs 👆</span>
                            </p>
                        </div>
                    </a>
                </div>
                <div className="section-bloc-text">
                    <a className="link-services" href="/contact">
                        <div className='section-text-picture'>
                            <h2 className="section-bloc-h2">Réparation de téléphones</h2>
                            <p className="section-bloc-text-text">
                                Oups votre téléphone ou votre tablette est tombé. Il y a eu de la casse ?<br/><br/>
                                Tout n'est pas perdu, il n'est pas nécessaire de le jetter. Contactez-nous afin que nous puissions voir si nous pouvons prendre en charge cet incident.<br/><br/>
                                Nous pouvons intervenir sur des changements de matériels : batterie, écran, connecteur de charge, vitre arrière, carte mère, etc.<br/><br/>
                                <span className='click-prices'>📱Tarifs sur demande 📧</span>
                            </p>
                        </div>
                    </a>
                </div>
                <div className="section-bloc-text">
                    <a className="link-services" href="/contact">
                        <div className='section-text-picture'>
                            <h2 className="section-bloc-h2">Reconditionnement PC fixes et portables</h2>
                            <p className="section-bloc-text-text">
                                Afin de prolonger la durée de vie des appareil, réduire les déchets électroniques, tout en offrant une alternative plus économique à l'achat d'un ordinateur neuf, nous nous lançons dans le reconditionnement.<br/><br/>
                                L'objectif derrière tout ça est de remettre à neuf un ordinateur en lui redonnant une seconde vie.<br/><br/>
                                <span className='click-prices'>📱Tarifs sur demande 📧</span>
                            </p>
                        </div>
                    </a>
                </div>
                <div className="section-bloc-text">
                    <a className="link-services" href="/contact">
                        <div className='section-text-picture'>
                            <h2 className="section-bloc-h2">Formation numérique</h2>
                            <p className="section-bloc-text-text">
                                Vous vous sentez perdu dans l'utilisation de votre outil numérique. Nous pouvons vous accompagner dans l'apprentissage et l'utilisation de votre ordinateur en allant de votre navigateur à vos logiciels bureautiques par exemple.<br/><br/>
                                N'hésitez pas à faire une demande, nous nous adaptons lorsque cela est nécessaire.<br/><br/>
                                <span className='click-prices'>📱Tarifs sur demande 📧</span>
                            </p>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}