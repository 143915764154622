import React from 'react'

import './Confidential.css'

function Confidential() {
  const title = "Dev&Info"
  return (
    <div className="section-confidentialite">
      <h1 className="section-big-title-confidential">Politique de confidentialité</h1>
      <article  class ="bloc-text-1">
          <h2 className="subtitle-confidential">Qui sommes-nous ?</h2>
          <p className="description-confidential">{title} est une entreprise de maintenance, dépannage et vente informatique à domicile,
            basée au 279 chemin du Suchaut 24750 Cornille. Il vous est possible de nous contacter par téléphone
            au 06.82.84.47.60 ou par mail à dlc.devandinfo@gmail.com</p>
      </article>
      <article  class ="bloc-text-2">
          <h2 className="subtitle-confidential">Utilisation et conservation des données collectées</h2>
          <p className="description-confidential">Depuis le 25 mai 2018, le nouveau Règlement Général relatif à la Protection des Données
            fait évoluer la Loi dite « Informatique et Liberté ». 
            Ce règlement encadre l’utilisation et la sécurisation de données à caractère personnel
            et vise à en assurer une meilleure protection, en octroyant le droit au client d’obtenir
            des informations sur les données récoltés par {title}, demander à les modifier, les corriger,
            les restreindre ou à les supprimer (à noter que des dispositions légales nous imposent
            de conserver vos données).
            Ces dernières peuvent collectées lors de nos échanges téléphoniques, mail, courrier postal
            ou encore lors d’interventions au domicile des clients afin d'assurer un meilleur suivi
            auprès de nos clients. Ces données seront conservées durant 5 ans.
            Le client peut toutefois exercer ses droits en envoyant un courrier
            à l’attention de Mme Laget-Chappert Dorothée {title} 279 chemin du Suchaut 24750 Cornille.
          </p>
      </article>
    </div>
  )
}

export default Confidential