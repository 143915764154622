import React from 'react'
import logotext from '../../assets/text_logo.png'
import Facebook from '../../assets/facebook.png'

import './NavBar.css'

export default function NavBar() {
  return (
        <nav class="nav">
        <a href="/"><img className='logotext' src={logotext} alt="logo"/></a>
        <input type="checkbox" id="nav-check"/>
        <div class="nav-header">
        </div>
        <div class="nav-btn">
          <label for="nav-check">
            <span></span>
            <span></span>
            <span></span>
          </label>
        </div>
        <div class="nav-links">
          <a href="/services">Prestations</a>
          <a href="/prices">Tarifs</a>
          <a href="/contact">Contact</a>
          <a href='https://www.facebook.com/devandinfo' rel='noopener noreferrer' target='_blank'>
            <img src={Facebook} alt="facebook" className='icon-holder'/>
          </a>
        </div>
      </nav>
  )
}
